.project-highlight-container{
  transition: opacity .5s ease-in-out;
  width:100%;
  height: 400px;
  margin-bottom: 0;
  color:white;
  border-radius:5px;
  overflow:hidden;
  cursor:pointer;
}

.project-highlight-container::after{
  content:"";
  position:absolute;
  left:0;
  top:0;
  width:100vw;
  height:inherit;
  border-radius:5px;
  background: linear-gradient(12deg, rgba(0,0,0,.85) 10%, rgba(0,212,255,0) 50%);  
  z-index:4;
}

.project-highlight-text{
  position:absolute;
  bottom:0;
  padding:10px 0px 30px 20px;
  font-size:75%;
  z-index:5;
}
.project-highlight-text h1, .project-highlight-text h2{
  padding:0;
  margin:0;
}
.project-highlight-text h2{
  font-weight:300;
}

.project-highlight-image{
  position:absolute;
  height:100%;
  left:50%;
  transform:translateX(-50%) scale(1.2);
  border-radius:5px;
  
}

.project-highlight-image-blur{
  height:100%;
  width:100%;
  filter:blur(10px);
  transform:scale(2);
  transform-origin:center;
  border-radius:5px;
  z-index:1;
}

@media (max-width:600px){
  .project-highlight-container{
    height:300px;
    font-size:80%;
  }
}

