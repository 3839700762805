.project-card {
  transition:all .15s ease-in-out;
  position: relative;
  width: 200px;
  height: 300px;
  border-radius: 7px;
  background: var(--background);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 0px 10px -3px rgba(0,0,0,.25);

}
.project-card img{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.project-card-img {
  transition:all .15s ease-in-out;
  position: absolute;
  left: 0;
  object-fit: cover;
  object-position: 50% 25%;
  opacity: .65;
  /* filter: contrast(0.75) brightness(.75) grayscale(25%); */
}
.project-card-text {
  transition:all .15s ease-in-out;
  position: absolute;
  width: 100%;
  bottom:0;
  color: white;
  font-size:75%;
  text-align:center;
  line-height:1.75em;
  background:linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
}
.project-card-text h2 {
  font-weight: 500;
}
.project-card-text h3 {
  font-weight: 400;
  font-size:.5em;
  margin-bottom:10px;
}
.project-card-text-horizontal-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.507);
  width: 50%;
  left:50%;
  transform:translateX(-50%);
  position:relative;
  margin:10px 0px 10px 0px;
}

/* Globals _________________________*/
.hidden {
  display: none;
}


@media (max-width:500px){
  .project-card{
    width:100px;
    height:210px;
    font-size:75%;
  }
  .project-card-text-horizontal-line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    margin:5px 0px 5px 0px;
  }
}