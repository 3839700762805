.project-search-holder {
  margin: 0 auto;
  width: 50%;
  position: relative;
  display: flex;
  top: 10px;
}

.project-search-input {
  width: 100%;
  border-right: none;
  padding: 5px;
  height: 20px;
  border: none;
  border-radius: 5px 0px 0px 5px;
  color: rgba(255, 255, 255, 0.7);
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.project-search-input::placeholder {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.searchButton {
  width: 40px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  border: none;
  cursor: pointer;
  font-size: 20px;
}
