* {
  padding: 0;
  margin: 0;
}
.about-me-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  background: rgb(20, 20, 30);
  transition: background 1s ease-in-out;
}

.page {
  width: 100%;
  height: 80vh;
  padding: 10vh 0 10vh 0;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.project-page-bttn {
  transition: background 0.25s ease-in-out;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid rgb(67, 67, 101);
  background: transparent;
  color: white;
  font-size: 100%;
  position: absolute;
  top: 90vh;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: projectPageBttnAnimation 2s ease-in-out infinite;
}

#contact-me-holder-about-me {
  position: relative;
  width: 85%;
  padding: 10vh 0;
  max-height: 98%;
  left: 50%;
  transform: translateX(-50%);
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  color: white;
  text-align: center;
  z-index: 100;
  border-radius: 4px;
  background: rgb(53, 53, 65);
}

.loading {
  margin-left: 1rem;
  font-size: 300%;
  animation: loadingSpinner 2s linear infinite;
}

@keyframes projectPageBttnAnimation {
  0% {
    background: transparent;
    border: 1px solid rgb(67, 67, 101);
  }
  20% {
    background: rgba(67, 67, 101, 0.5);
    border: 1px solid rgb(9, 9, 101);
    filter: drop-shadow(0px -1px 10px rgba(255, 255, 255, 0.25));
  }
  60% {
    background: transparent;
    border: 1px solid rgb(67, 67, 101);
  }
  100% {
    background: transparent;
    border: 1px solid rgb(67, 67, 101);
  }
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg) scale(0.85);

    opacity: 0.5;
  }
  100% {
    transform: rotate(0deg) scale(1);

    transform: rotate(360deg);
    opacity: 1;
  }
}
