.contact-me-floater {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0vw;
  height: 0vh;
  bottom: 0;
  right: 0;
  margin: 20px;
  padding: 25px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s;
}

.contact-me-floater i {
  cursor: pointer;
  user-select: none;
  color: black;
  font-size: 2em;
  z-index: 10000;
}

.contact-me-floater-clicked {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  width: 50vw;
  height: 60vh;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

/* Contact Me _________________________*/
#contact-me-holder {
  text-align: center;
  z-index: 100;
  width: 100%;
}

.email,
.message {
  opacity: 1;
  font-size: 1em;
  padding: 0.5em;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  background: #a2abb4;
  color: white;
  outline: none;
  text-align: left;
  resize: none;
  overflow: scroll;
}
.message {
  min-height: 20vh;
  resize: none;
}

.email::placeholder,
.message::placeholder {
  font-family: "Poppins", sans-serif;
  color: white;
  text-align: center;
}
.submit {
  transition: all 0.25s ease-in;
  color: white;
  background: #719e64;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  font-size: 100%;
  user-select: none;
}
