.project-detail-page{
  position:fixed;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background: rgb(20, 20, 30);
  background: radial-gradient(circle, rgb(51, 51, 72) 0%, rgb(20, 20, 30) 100%);
  z-index:101;
  color:white;
}
.close-project-detail-page{
  transition:all .15s ease-in-out;
  position:absolute;
  margin:20px;
  font-size:150%;
  z-index:102;
  user-select:none;
  cursor:pointer;
}
.close-project-detail-page:active{
  transition:all .15s ease-in-out;
  opacity:.5;
}
.project-detail-text{
  position:absolute;
  left:50%;
  top:0;
  transform:translateX(-50%);
  text-align:center;
  margin-top:20px;
  font-size: 1.3rem;
}
.project-detail-swiper{
  position:absolute;
  width:100%;
  height:50%;
  top:50%;
  transform:translateY(-60%);
  z-index:2;
}
.project-detail-swiper .swiper-pagination{
  position:relative;
}
iframe{
  transform:translateX(12.5%);
  width:80vw;
}

.award-holder{
  position:absolute;
  width:100vw;
  padding:0;
  margin:0;
  background:red;
}




.horizontal-line {
  position:relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.507);
  width: 50%;
  left:50%;
  transform:translate(-50%);
}
.visible{
  display:block;
}
.hidden{
  display:none;
}