#logo-container {
  width: 80vw;
  margin: auto;
  transform: translatex(0px);
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  overflow: hidden;
  transform: translateY(-4vh);
}

.logo {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: all 0.25s cubic-bezier(0.185, 0.705, 0.365, 0.95);
}

.logo:hover {
  opacity: 1;
  transition: all 0.25s cubic-bezier(0.185, 0.705, 0.365, 0.95);
}

.logo img {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  transition: all 0.25s cubic-bezier(0.185, 0.705, 0.365, 0.95);
  user-select: none;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  #logo-container {
    width: 90vw;
  }
  .logo {
    width: 50px;
    height: 50px;
    opacity: 1;
  }
}
