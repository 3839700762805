.project-award{
  position:absolute;
  left:100%;
  top:0;
  width:50px;
  height:50px;
  padding:5px;
  transform:translateX(-100%);
  align-self:flex-start;
  z-index:3;
}
.project-award img{
  width:100%;
}

@media (max-width:500px){
  .project-award{
    width:25px;
    height:25px;
  }
}