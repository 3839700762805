.text-block {
  transition: opacity 1s ease-in-out;
  position: relative;
  float: left;
  text-align: center;
  color: white;
  font-size: 150%;
  margin-bottom: 10vh;
  margin-top: 2vh;
  width: 85%;
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-weight: 600;
  width: fit-content;
}

.sub-header {
  font-weight: 100;
  width: fit-content;
  font-size: 75%;
}

.body {
  font-weight: 200;
  font-size: 75%;
}

.text-block-horizontal-line {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 0.5px;
  margin: 10px 0px 30px 0px;
}

@media (max-width: 500px) {
  .text-block {
    width: 90%;
    font-size: 120%;
    padding-bottom: 15vh;
  }
}
